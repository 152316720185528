html, body {
    margin: 0;
}

body {
    scrollbar-color: #06062e #f5f5ff;
    scrollbar-width: thin;

}

/* li {
    list-style: none;
} */

table, thead, tbody, th, tr, td{
    border: 1px solid black;
}

#mainBody {
    position: relative;
}

* {
    box-sizing: border-box;
}

a {
    color: rgba(75, 172, 216, 1);
    text-decoration: none;
}

a:hover {
    color: rgba(75, 172, 216, .8);
}

.code{
    margin-bottom: 10px;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 40%;
}

.w-90 {
    width: 40%;
}

.soapUI img{
    width: 70%;
    padding-bottom: 20px;
}

.text-justify{
    text-align: justify;
}

.pt-page {
    padding-top: 70px;
}

/*Navigation Bar*/
.navbar {
    position: fixed;
    background-color: white;
    padding: 0;
}

.navbar-brand {
    margin: 10px 10px 10px 10px
}

.navbar-brand, .navbar-nav .nav-link {
    color: white;
}

img.navLogo {
    width: 30%;
}

.navbar-nav {
    background-color: #00215e;
    line-height: 40px;

}

.navbar-nav .nav-link {
    text-transform: uppercase;
    font-size: 17px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
}

.nav-link:hover {
    background-color: #dc242a;
    border-radius: 30px 0px 30px 0px;
}

.navbar-collapse {
    text-align: center;
}

.navContainer {
    width: 100%;
    background-color: white;
}

/*Navigation Bar*/

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.login-window {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.center-window {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.login-container {
    position: relative;
    width: 450px;
    padding: 20px;
}

.logo {
    position: absolute;
    width: 80%;
    top: -60px;
    left: calc(50% - 180px);
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form input {
    padding: 10px;
    border-radius: 2px;
    border: none;
}

.form.mb-10 input {
    margin-bottom: 10px;
}

.form.mb-20 input {
    margin-bottom: 20px;
}

#mail-input:focus,
#btn-input:focus,
#pw-input:focus {
    outline: none;
}

#logout-btn {
    padding-right: 20px;
    background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='000000' class='bi bi-box-arrow-right' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'/><path fill-rule='evenodd' d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'/></svg>") no-repeat right;
    border-radius: 2px;
    border: none;
    color: black;
}

.bg-blue {
    background-color: rgba(75, 172, 216, 1);
    transition: all 0.3ms ease-in-out;
}

.bg-blue:hover {
    background-color: rgba(75, 172, 216, .8);
}

.bg-darkblue {
    background-color: #06062e;
}

#header {
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
}

#header + div {
    align-items: center;
}

#mobileHeader {
    display: none;
}

#sidebar ul li {
    border-radius: 0;
    border: none;
    padding: 0;
}

#sidebar ul li a {
    padding: .5rem 1rem;
    display: block;
    width: 100%;
    text-decoration: none;
}

.list-heading {
    padding: .5rem 1rem;
    display: block;
    width: 100%;
    font-weight: bold;
}

#content {
    background-color: rgba(51, 51, 51, .05);
    /*padding: 20px;*/
    height: calc(100vh);
    overflow-y: auto;
    animation: fadeIn 200ms ease-in;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.header-logo {
    width: 210px;
    transform: translateZ(0);
}

.name {
    color: black;
}

.mw-400 {
    max-width: 400px !important;
}

input:disabled {
    background-color: #e3e3e3;
}

.stav-0 {
    font-weight: bold;

}

.stav-1 {
    color: rgb(59, 198, 59) !important;
    font-weight: bold;

}

.stav-2 {
    color: rgb(198, 59, 59) !important;
    font-weight: bold;

}

.data-row:hover {
    cursor: pointer;
    background-color: rgb(205, 205, 215);
}

.data-row {
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.data-table {
    max-height: calc(100vh - 199.05px);
    overflow: auto;
}

.data-table table thead {
    position: sticky;
    top: 0;
    background-color: #f5f5ff;
    z-index: 20;
    border-bottom: solid;
}

.date-input {
    flex: 0 1 45%;

}

.list-group {
    list-style: none;
}

.list-group-item {
    background-color: transparent;

}

.pe-link {
    color: #ff5000;
    position: relative;
    overflow: hidden;
    transition: 300ms ease-in-out;
}

.pe-link:hover:before,
.pe-link:hover:after {
    transform: translateX(0);
}

.pe-link:before, .pe-link:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    transform: translateX(-100%);
    z-index: -1;
}

.pe-link:before {
    background-color: #ff5000;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out, -moz-transform 300ms ease-in-out,
    -o-transform 300ms ease-in-out;
}

.pe-link:after {

    background-color: #f00;
    transition: transform 300ms ease-in-out 100ms, -webkit-transform 300ms ease-in-out 100ms, -moz-transform 300ms ease-in-out 100ms, -o-transform 300ms ease-in-out 100ms;
}

.main-row {
    flex-direction: column;
    height: 100%;
}

.detail-heading {
    color: rgb(105, 105, 115);
    margin-bottom: 0.25rem;
}

#submit-deny-but {
    background-color: #d00000;
}

#submit-approve-but {
    background-color: #008000;
}

#submit-deny-but, #submit-approve-but {
    border: none;
    border-radius: 2px;
    padding: 10px;
    color: #fff;
    width: 100px;
}

.edit-page-form {
    position: absolute;
    right: 0;
    width: auto;
}

#mail-input, #pw-input {
    border: 1px solid black;
}

#pw-input.error {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation: shake 700ms;

}

.ck.ck-editor {
    width: 100%;
}

#buttons-edit {
    margin-bottom: 15px;
}

.btn-text {
    position: relative;
    z-index: 2;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
        background-color: #fff;
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
        background-color: rgba(208, 0, 0, 1);
    }
}

.h-100vh {
    height: 100vh;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
}

.flex-container > a, .flex-container > div {
    display: flex;
}

.card {
    text-align: center;
    flex: 0 0 300px;
    margin: 10px;
    height: 130px;
    border: 1px solid black;
}

.card-body p {
    font-size: 25px;
}

.sqlTeorieList ul {
    column-count: 2;
}

.sqlTeorieSpan {
    color: #E0002D;
}